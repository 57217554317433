// ----- COOKIES
export const cookiesForDev = false // **** CHANGE TO FALSE IN PRODUCTION ****
export const DOMAIN = '.teoloro.com'
// export const DOMAIN = '.testlorotest.xyz'

// ----- ACCOUNT
export const ACCOUNT_BACKEND = 'https://acbk' + DOMAIN
export const ACCOUNT_FRONTEND = 'https://account' + DOMAIN

// ----- GAME
export const GAME_BACKEND = 'https://lgbk' + DOMAIN
export const GAME_FRONTEND = 'https://game' + DOMAIN

// ----- CLASS
export const CLASS_BACKEND = 'https://clbk' + DOMAIN
export const CLASS_FRONTEND = 'https://class' + DOMAIN

// ----- CHAT
export const CHAT_FRONTEND = `https://chat${DOMAIN}`

// ***** CHANGE DEPENDING ON THE PROJECT *****
export const BASE_API = GAME_BACKEND
// export const BASE_API = 'http://127.0.0.1:8001'
